// bloa-wpo-dd 转 Bloa Wpo Dd 形式
export  function formatStringKebab2UpperCase(input) {
    return input
        .split('-') // 将字符串以 '-' 分隔为数组
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // 每个单词首字母大写，其他字母小写
        .join(' '); // 将数组元素以空格连接为字符串
}

export function setMetaTag(name, content) {
	let element = document.querySelector(`meta[name="${name}"]`)
	if (element) {
		element.setAttribute('content', content)
	} else {
		element = document.createElement('meta')
		element.setAttribute('name', name)
		element.setAttribute('content', content)
		document.head.appendChild(element)
	}
}